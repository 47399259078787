import React from 'react';

const Fun = () => (
  <div class='container'>
    <div class='jumbotron p-3 p-md-5 text-white rounded important'>
      <div class='col-md-6 px-0'>
        <h1 class='display-4 font-italic'>Fun-ish time</h1>
        <p class='lead my-3'>somewhat RELAXATIONESSESSESS!!!!!!!!!</p>
        <p class='lead mb-0'>ThEnd...</p>
      </div>
    </div>

    <div class='row mb-2'>
      <div class='col-md-6'>
        <div id='sandiego' class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-info'>Summer 2021</strong>
            <h3 class='mb-0'>
              <a href='/' class='text-dark'>
                Mountain Biking!
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              Mountain biking. Mountain biking. Mountain biking. Mountain
              biking. Mountain biking. Mountain biking. Mountain biking.
              Mountain biking. Mountain biking. Mountain biking. Mountain
              biking. Mountain biking. Mountain biking. Mountain biking.
            </p>
          </div>

          <img
            class='card-img-right page-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='biking'
            src='images/biking.jpg'
            data-holder-rendered='true'
            data-toggle='modal'
            data-target='#bikeModal'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div id='sandiego' class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-danger'>July 2018</strong>
            <h3 class='mb-0'>
              <a href='/' class='text-dark'>
                Mountain Biking!
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              I go mountain biking a lot. It's fun and sometimes we biked to
              Albertson's after to get some ice cream or donuts. All our
              exercise gone hehehe, but it was scrumptious. I usually go on the
              Ladera Ranch trail.
            </p>
          </div>

          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='biking'
            src='images/bik.jpg'
            data-holder-rendered='true'
            data-toggle='modal'
            data-target='#bikeModal'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div id='irvine' class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-warning'>July 2018</strong>
            <h3 class='mb-0'>
              <a href='/' class='text-dark'>
                Sky Scraper
              </a>
            </h3>
            <p class='card-text mb-auto'>
              I watched Sky Scraper at the theatre. When we were watching T.V.
              my dad saw the trailer on there and wanted to watch it, so we did.
              It was ehhhh ok.{' '}
            </p>
          </div>

          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/movie.jpg'
            data-holder-rendered='true'
            data-toggle='modal'
            data-target='#movieModal'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div id='irvine' class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a href='/' class='text-dark'>
                Friday Concerts
              </a>
            </h3>
            <p class='card-text mb-auto'>
              Sometimes on Friday we go to an outdoor concert. It's usually
              really fun because I find my friends to play with.{' '}
            </p>
          </div>

          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/cert.jpg'
            data-holder-rendered='true'
            data-toggle='modal'
            data-target='#concertModal'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div id='irvine' class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-danger'>July 2018</strong>
            <h3 class='mb-0'>
              <a href='/' class='text-dark'>
                Tony's Game
              </a>
            </h3>
            <p class='card-text mb-auto'>
              Tony is my brother. This was when I was watching one of his soccer
              games. It wasn't "fun" but I had to be there :D.{' '}
            </p>
          </div>

          <img
            class='card-img-right top-image'
            data-toggle='modal'
            data-target='#soccerModal'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/emilyisawesome.png'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div id='irvine' class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-info'>August 2018</strong>
            <h3 class='mb-0'>
              <a href='/' class='text-dark'>
                Rubric's Cube
              </a>
            </h3>
            <p class='card-text mb-auto'>
              I memorized how to solve a rubric's cube. I'm probably going to
              forget most of it in a few days, but I'm still proudish. ;){' '}
            </p>
          </div>

          <img
            class='card-img-right top-image'
            data-toggle='modal'
            data-target='#cubeModal'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/cube.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
    </div>
  </div>
);

export default Fun;
