import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';


const useStyles = makeStyles({
    header: {
        position: 'relative',
        background: 'linear-gradient(to right, #ffcc80, #ffa726)',
        textAlign: 'center',
        color: 'white',
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontFamily: 'Montserrat, sans-serif',
    },
    headerTitle: {
        fontSize: '64px',
        fontWeight: '700',
        textTransform: 'uppercase',
        letterSpacing: '4px',
        marginBottom: '10px',
    },
    headerSubtitle: {
        fontSize: '32px',
        textTransform: 'uppercase',
        letterSpacing: '2px',
    },
    carouselContainer: {
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
    },

    slideImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '10px',
    },
    slideContent: {
        position: 'absolute',
        top: 480,
        left: 0,
        width: '100%',
        height: '100',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        background: 'rgba(255, 255, 255, 0.4)', // Add a slight background to improve readability
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',

    },
    slideTitle: {
        fontSize: '24px',
        fontWeight: '700',
        margin: '10px 0',
    },
    slideDescription: {
        fontSize: '18px',
        lineHeight: '1.5',
    },
    slideImageContainer: {
        width: '100%',
        height: 0,
        paddingTop: '75%', // 16:9 aspect ratio (change this value to adjust the height)
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },

});

const Roadtrip = () => {
    const classes = useStyles();
    const name = ' ';

    // Define the array of slides for the slideshow
    const slides = [
        {
            title: 'Day 1: Las Vegas',
            imageUrl: 'images/nevada.JPG',
            description:
                'The Bellagio water show^^ super crowded + also saw a giant ball',
        },
        {
            title: 'Day 2: Near Arches National Park, Colorado River, Utah',
            imageUrl: 'images/utah.jpg',
            description: 'We never got into the actual national park, but still pretty',
        },
        {
            title: 'Day 3: Rocky Mountains, Colorado',
            imageUrl: 'images/colorado.jpg',
            description: 'Really cool + altitude sickness + a million mosquito bites + moose',
        },
        {
            title: 'Day 4: Through the plains of Kansas',
            imageUrl: 'images/kansas.jpg',
            description: 'Never ending flat grasslands... plus farms.',
        },
        {
            title: 'Day 5: Almost through Kansas',
            imageUrl: 'images/kansas20.jpg',
            description: 'Our first toll road of the trip',
        },
        {
            title: 'Day 6: Arches Gateway National Park',
            imageUrl: 'images/missouri21.jpg',
            description: 'Pretty cool and quuuuiiitteee tall (630 ft)',
        },
        {
            title: 'Day 6: Notre Dame',
            imageUrl: 'images/indiana22.jpg',
            description: 'We definitely had good first impressions...',
        },
        {
            title: 'Day 7: Cornell University',
            imageUrl: 'images/newyork23.jpg',
            description: 'We were toured by an alumni we happened to meet named Marie',
        },
        {
            title: 'Day 8: On the road..',
            imageUrl: 'images/cornell24.JPG',
            description: 'Heading to our first week-long air bnb',
        },
        {
            title: 'Day 9: County Fair',
            imageUrl: 'images/carnival25.JPG',
            description: 'So many cars were parked here, but still dunno why...',
        },
        {
            title: 'Day 10: Lunch Time',
            imageUrl: 'images/food26.jpg',
            description: "Dining in at Aiello's Ristorante - yummy",
        },
        {
            title: 'Day 11: Morning Runs',
            imageUrl: 'images/pushup27.JPG',
            description: '3-4 mile runs, some push-ups, a few hill sprints',
        },
        {
            title: 'Day 12: Lunch Break',
            imageUrl: 'images/whitneypoint28.jpg',
            description: 'Pizza from Aiellos Ristorante - also yum',
        },
        {
            title: 'Day 13: Cornell University - Part 2',
            imageUrl: 'images/newyork29.JPG',
            description: 'We went through trails, museums, different parts of campus',
        },
        {
            title: 'Day 14: Soccer Practice',
            imageUrl: 'images/ithaca30.JPG',
            description: 'Every evening, we got soccer as per usual',
        },

        // Add more slides here
    ];

    const sliderSettings = {
        dots: false, // Set to false to hide dots
        arrows: true, // Set to true to show arrows
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Show one slide at a time
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <ArrowRightIcon color='primary' />,
        prevArrow: <ArrowLeftIcon color='primary' />
    };
    return (
        <div>
            <div className={classes.header}>
                <h1 className={classes.headerTitle}>ROADTRIP</h1>
                <p className={classes.headerSubtitle}>Cross-country: California to the East Coast...</p>
                <p className='lead mb-0'>
                    <a href='/' className='text-white font-weight-bold'>
                        07/15/2023 → 08/??/2023 {name}
                    </a>
                </p>
            </div>

            {/* Add the Carousel Slideshow here */}
            <div className={classes.carouselContainer}>

                <Slider {...sliderSettings}>
                    {slides.map((slide, index) => (
                        <div key={index} className={classes.slideImageContainer}>
                            <img className={classes.slideImage} src={slide.imageUrl} alt={slide.title} />
                            {/* Move the slideContent div here */}
                            <div className={classes.slideContent}>
                                <h3 className={classes.slideTitle}>{slide.title}</h3>
                                <p className={classes.slideDescription}>{slide.description}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <div className='row mb-2'>
                {/* Your other content here */}
                {/* ... */}
            </div>
        </div>
    );
};

export default Roadtrip;
