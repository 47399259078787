import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { Chart } from 'react-charts';

const useStyles = makeStyles({
  root: {
    margin: 100,
    minWidth: 275,
  },
});

const Graphing = () => {
  const classes = useStyles();
  // following is for chart 1 of 1 line
  const [a, setA] = useState('4');
  const [b, setB] = useState('3');
  const [c, setC] = useState('-9');

  // following code is for chart 2 of 2 lines
  const [a1, setA1] = useState('4');
  const [b1, setB1] = useState('3');
  const [c1, setC1] = useState('2');

  const [x, setX] = useState([0, 0]);
  const [cross, setCross] = useState([0, 0]);

  const [a2, setA2] = useState('-4');
  const [b2, setB2] = useState('-3');
  const [c2, setC2] = useState('20');
  const [answers, setAnswers] = useState([]);
  const [resultAnswers, setResultAnswers] = useState([]);
  const [dataForChart2, setDataForChart2] = useState([]);
  const [xArray, setXArray] = useState([]);
  const [dataRange] = useState({
    start: -10,
    end: 10,
    increase: 0.25,
    error: 3,
  });
  const makeUpX = (min: number, max: number, increase: number) => {
    let result = [];
    let num = min;
    while (num < max) {
      result.push(parseFloat(num.toFixed(2)));
      num += increase;
    }
    return result;
  };

  useEffect(() => {
    const { start, end, increase } = dataRange;
    setXArray(makeUpX(start, end, increase));
  }, [dataRange]);

  const processNum = (num: number) => {
    let _num = num.replace(/\D/g, '').trim();
    _num = num < 0 ? `-${_num}` : `${_num}`;
    return isNaN(_num) || _num === '' ? 0 : parseFloat(_num);
  };

  const result = xArray.map((x) => {
    const numberA = processNum(a);
    const numberB = processNum(b);
    const numberC = processNum(c);

    const y = x * x * numberA + x * numberB + numberC;
    return [x, y];
  });

  const data = [
    {
      label: 'My Magic',
      data: result,
    },
  ];

  const resultChart2Line1 = React.useMemo(
    () =>
      xArray.map((x) => {
        const numberA = processNum(a1);
        const numberB = processNum(b1);
        const numberC = processNum(c1);
        const y = x * x * numberA + x * numberB + numberC;
        return [x, parseFloat(y.toFixed(2))];
      }),
    [xArray, a1, b1, c1]
  );

  const resultChart2Line2 = React.useMemo(
    () =>
      xArray.map((x) => {
        const numberA = processNum(a2);
        const numberB = processNum(b2);
        const numberC = processNum(c2);
        const y = x * x * numberA + x * numberB + numberC;
        return [x, parseFloat(y.toFixed(2))];
      }),
    [xArray, a2, b2, c2]
  );

  const calculateX = (a, b, c) => {
    // y = ax² + bx + c
    const x1 = (Math.sqrt(b * b - 4 * a * c) - b) / (2 * a);
    const x2 = (-1 * Math.sqrt(b * b - 4 * a * c) - b) / (2 * a);
    return [x1, x2];
  };

  useEffect(() => {
    const _x = calculateX(a, b, c);
    setX(_x);
  }, [a, b, c]);

  useEffect(() => {
    const _cross = calculateX(
      parseFloat(a1) - parseFloat(a2),
      parseFloat(b1) - parseFloat(b2),
      parseFloat(c1) - parseFloat(c2)
    );
    setCross(_cross);
  }, [a1, b1, c1, a2, b2, c2]);

  useEffect(() => {
    let _answers = [];
    let _result = [];
    xArray.forEach((x) => {
      const numberA = processNum(a1);
      const numberB = processNum(b1);
      const numberC = processNum(c1);
      const y = x * x * numberA + x * numberB + numberC;

      const numberA2 = processNum(a2);
      const numberB2 = processNum(b2);
      const numberC2 = processNum(c2);
      const y2 = x * x * numberA2 + x * numberB2 + numberC2;

      if (Math.abs(y - y2) < dataRange.error) {
        _answers.push({ x: x, y: y, y2: y2, dif: Math.abs(y - y2) });
        _result.push([x, y]);
      }
    });
    setAnswers(_answers);
    setResultAnswers(_result);
  }, [xArray, a1, a2, b1, b2, c1, c2, dataRange.error]);

  useEffect(() => {
    setDataForChart2([
      {
        label: 'My Other Magic',
        data: resultChart2Line1,
      },
      {
        label: 'My Third Magic',
        data: resultChart2Line2,
      },
      {
        label: 'Answers',
        data: resultAnswers,
      },
    ]);
  }, [resultAnswers, resultChart2Line1, resultChart2Line2]);

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' },
    ],
    []
  );

  const series = React.useCallback(
    (s, i) => ({
      type: i === 2 ? 'bubble' : 'line',
    }),
    []
  );

  const getDatumStyle = React.useCallback(
    (datum) => ({
      r: datum.seriesIndex === 2 ? 7 : 2,
    }),
    []
  );

  const getSeriesStyle = React.useCallback((series) => {
    let c = `#17EAD9`;
    switch (series.index) {
      case 0:
        c = `#ff0000`;
        break;
      case 1:
        c = '#17EAD9';
        break;
      case 2:
        c = '#1e3ce6';
        break;

      default:
        break;
    }

    return {
      color: c,
      opacity: 0.5,
    };
  }, []);

  return (
    <div className='container'>
      <h1> Quadratic Equation </h1>
      <div id='chart 1'>
        <h3>
          y = {a}x² + {b}x + {c}
        </h3>
        <h5>
          if y = 0 then <br />
          x1:{x.length > 1 ? x[0] : '0'} <br />
          x2:{x.length > 1 ? x[1] : '0'}
        </h5>

        <Box textAlign='center'>
          <Box component='span' m={1}>
            y =
          </Box>

          <TextField
            name='inputA'
            value={a}
            label='a'
            onChange={(e) => {
              console.log(e);

              setA(e.target.value);
            }}
          />

          <Box component='span' m={1}>
            x² +
          </Box>
          <TextField
            name='inputB'
            label='b'
            value={b}
            onChange={(e) => setB(e.target.value)}
          />

          <Box component='span' m={1}>
            x +
          </Box>
          <TextField
            name='inputC'
            label='c'
            value={c}
            onChange={(e) => setC(e.target.value)}
          />
        </Box>
        <Box textAlign='center'>
          <div
            style={{
              textAlign: 'center',
              margin: 'auto',
              width: '600px',
              height: '400px',
            }}
          >
            <Chart data={data} axes={axes} tooltip />
          </div>
        </Box>
      </div>

      <div id='chart 2'>
        <h1> Quadratic System </h1>
        <div>
          <h3>
            y = {a1}x² + {b1}x + {c1}
          </h3>
          <Box textAlign='center'>
            <Box component='span' m={1}>
              y =
            </Box>

            <TextField
              name='inputA1'
              value={a1}
              label='a1'
              onChange={(e) => {
                console.log(e);
                setA1(e.target.value);
              }}
            />

            <Box component='span' m={1}>
              x² +
            </Box>
            <TextField
              name='inputB1'
              label='b1'
              value={b1}
              onChange={(e) => setB1(e.target.value)}
            />

            <Box component='span' m={1}>
              x +
            </Box>
            <TextField
              name='inputC1'
              label='c1'
              value={c1}
              onChange={(e) => setC1(e.target.value)}
            />
          </Box>
        </div>
        <hr />
        <div>
          <h3>
            y = {a2}x² + {b2}x + {c2}
          </h3>
          <Box textAlign='center'>
            <Box component='span' m={1}>
              y =
            </Box>

            <TextField
              name='inputA2'
              value={a2}
              label='a2'
              onChange={(e) => {
                console.log(e);
                setA2(e.target.value);
              }}
            />

            <Box component='span' m={1}>
              x² +
            </Box>
            <TextField
              name='inputB2'
              label='b2'
              value={b2}
              onChange={(e) => setB2(e.target.value)}
            />

            <Box component='span' m={1}>
              x +
            </Box>
            <TextField
              name='inputC2'
              label='c2'
              value={c2}
              onChange={(e) => setC2(e.target.value)}
            />
          </Box>
        </div>
        <hr />
        <Box textAlign='center'>
          <div
            style={{
              textAlign: 'center',
              margin: 'auto',
              width: '600px',
              height: '400px',
            }}
          >
            <Chart
              data={dataForChart2}
              axes={axes}
              series={series}
              getSeriesStyle={getSeriesStyle}
              getDatumStyle={getDatumStyle}
              tooltip
            />
          </div>
          <h2>Answer(s)</h2>
          <h5>
            if y1 = y2 then <br />
            x1:{cross[0]} <br />y ={' '}
            {cross[0] * cross[0] * a1 + b1 * cross[0] + c1} <br />
            x2:{cross[1]} <br />y ={' '}
            {cross[1] * cross[1] * a1 + b1 * cross[1] + c1}
          </h5>

          <h3>
            {answers &&
              answers.map((a, idx) => (
                <div key={idx} className={classes.root}>
                  x: {a.x.toFixed(1)} <br />
                  y1:{a.y.toFixed(1)} <br /> y2: {a.y2.toFixed(1)} <br />
                  difference:
                  {a.dif.toFixed(1)} ({`<=`}
                  {dataRange.error})
                  <hr />
                </div>
              ))}
          </h3>
        </Box>
      </div>
    </div>
  );
};
export default Graphing;
