import React from 'react';

const data = [
  {
    img: 'images/hotchkiss1.jpg',
    caption: `The Campus`,
  },
  {
    img: 'images/hotchkiss2.jpg',
    caption: 'The Packaging',
  },
  {
    img: 'images/hotchkiss3.png',
    caption: 'The Hotchkiss Shirt',
  },
  {
    img: 'images/hotchkiss4.jpg',
    caption: 'The Car Sticker',
  },
  {
    img: 'images/hotchkiss5.png',
    caption: 'The Acceptance-ness',
  },
  {
    img: 'images/hotchkiss6.png',
    caption: 'The DIY Light Thing',
  },
];
const Hotchkiss = () => (
  <div class='container'>
    <div class='jumbotron p-3 p-md-5 text-white rounded hotchkiss'>
      <div class='row mb-2'>
        <div class='col-md-8 px-0'>
          <h1 class='display-4 font-italic'>Hotchkiss</h1>
          <p class='lead my-3'>Highschool - Founded in 1891</p>
          <p class='lead mb-0'>11 Interlaken Road Lakeville, CT 06039</p>
        </div>
        <div class='col-md-4 px-0'>
          {' '}
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='hotchkiss_logo'
            src='images/hotchkiss_logo.png'
            data-holder-rendered='true'
            data-toggle='modal'
            data-target='#hotchkiss_logoModal'
          />
        </div>{' '}
      </div>
    </div>

    <div class='row mb-2'>
      <div class='col-md-12'>
        <div class='row mb-2'>
          {data.map((image) => (
            <div class='col-md-6'>
              <div id='grad' class='card flex-md-row mb-4 box-shadow h-md-250'>
                <div class='card-body d-flex flex-column align-items-start'>
                  <img
                    class='bigimage'
                    data-src='holder.js/200x250?theme=thumb'
                    alt='hotchkiss'
                    src={`${image.img}`}
                    data-holder-rendered='true'
                    data-toggle='modal'
                    data-target='#hotchkissModal'
                  />

                  <p class='card-text mb-auto mt-3'> {image.caption}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Hotchkiss;
