import React from 'react'

const Header = () => (
  <div>
    <header>
      <div className='bg-dark collapse hide' id='navbarHeader'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-8 col-md-7 py-4'>
              <h4 className='text-white'>About</h4>
              <p className='text-muted'>
                I thought it'd be cool if I kept what I said every year to see
                what changed and stuff, so... Hi, my name is Emily and it's June
                22, 2022. I just finished my first year at Hotchiss and it was
                amazing. I did some dumb things, but I'm still thriving, so
                we're good. I enjoy soccer and track, but right now I'm really
                out of shape cuz: 1. I got covid and 2. I was on the academic
                grinnddddd and literally never went out to exercise, so......
                yea:) Anyways, more about me: I love Dadish with all my heart,
                I'm for sure a dedicated DNF worshipper, and I'm pro at Hayday.
                Ok bye o7 ------------------ Hiii, my name is Emily and here's
                stuff about me: Soccer is my favorite sport. I used to do swimm
                and tennis. I like sour candies, chocolate, and caramels. ♥
                Snacking. I know some Chinese and a tiny bit of spanish that's
                slowly being forgotten. I'm 13. My favorite color is turquiose.
                My grades are usually an A, and I'm in accelerated classes. I
                like reading, and the Keeper of the Lost Cities series is the
                best. I also enjoy art and baking. I hope that one day I get to
                go skydiving and a place with zero gravity. That's basically
                it:P Byeeeeeee.
              </p>
            </div>
            <div className='col-sm-4 offset-md-1 py-4'>
              <h4 className='text-white'>Contact</h4>
              <ul className='list-unstyled'>
                <li>
                  {/*
                  <a
                  
                    href='mailto:eswang37@gmail.com'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-white'
                  >
                   */}
                  Just kidding. No contact for you:)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>

    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <a className='navbar-brand' href='/'>
        <img
          id='logo'
          alt='Logo'
          src='images/logo50.png'
          width='50'
          height='50'
        />
      </a>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarSupportedContent'
        aria-controls='navbarSupportedContent'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>

      <div className='collapse navbar-collapse' id='navbarSupportedContent'>
        <ul className='navbar-nav mr-auto'>
          <li className='nav-item active'>
            <a className='nav-link' href='/'>
              Home
              <span className='sr-only'>(current)</span>
            </a>
          </li>

          <li className='nav-item dropdown'>
            <a
              className='nav-link dropdown-toggle'
              href='/'
              id='navbarDropdown'
              role='button'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              Things
            </a>
            <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
              <a className='dropdown-item' href='/roadtrip'>
                {' '}
                Roadtrip
              </a>
              <a className='dropdown-item' href='/chinese'>
                Learning Chinese
              </a>
              <a className='dropdown-item' href='/art'>
                Time 4 Art
              </a>
              <a className='dropdown-item' href='/programming'>
                Programming
              </a>
              <a className='dropdown-item' href='/books'>
                Books 4 Me
              </a>
              <a className='dropdown-item' href='/soccer'>
                Soccer
              </a>
              <a className='dropdown-item' href='/camps'>
                Camps
              </a>


              <div className='dropdown-divider'></div>
              <a className='dropdown-item' href='/fun'>
                {' '}
                Fun-ness
              </a>
              <a className='dropdown-item' href='/donations'>
                Donations
              </a>
              <a className='dropdown-item' href='/grad'>
                {' '}
                Middle School Grad
              </a>
              <a className='dropdown-item' href='/hotchkiss'>
                {' '}
                Hotchkiss
              </a>
              <a className='dropdown-item' href='/volunteer'>
                {' '}
                Volunteer
              </a>

              {/*	<div className="dropdown-divider"></div>
							<a className="dropdown-item" href="/soccer/sandiego">San Diego Tornament</a>
							<a className="dropdown-item" href="/soccer/irvine">Irvine Tornament</a>
							*/}
            </div>
          </li>

          <li className='nav-item'>
            <a className='nav-link' href='http://emilyw717.blogspot.com'>
              My Blog
            </a>
          </li>

          <li className='nav-item'>
            <a
              className='nav-link'
              href='/'
              target='_blank'
              data-toggle='collapse'
              data-target='#navbarHeader'
            >
              About Me
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
)

export default Header
