import React from 'react'

const Programming = () => (
  <div class='container'>
    <div class='jumbotron p-3 p-md-5 text-white rounded bg-primary'>
      <div class='col-md-6 px-0'>
        <h1 class='display-4 font-italic'>Programming...</h1>
        <p class='lead my-3'>While learning code...</p>
        <p class='lead mb-0'>
          <a href='/' class='text-white font-weight-bold'>
            ThEnd...
          </a>
        </p>
      </div>
    </div>

    <div class='row mb-2'>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>June 2022</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                The Campaign
              </a>
            </h3>
            <p class='card-text mb-auto'>
              I'm not the one running, don't worry. So basically a friend (not
              mine) asked if we could make a website for her to help her whole
              campaign, and obviously being the amazing people we are, we said
              yea sure.{' '}
              <a
                href='https://melissa.andmap.co/'
                rel='noopener noreferrer'
                target='_blank'
              >
                Melissa.andmap.co
              </a>{' '}
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/logo4.png'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>June 2021</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Euro Cup
              </a>
            </h3>
            <p class='card-text mb-auto'>
              Tony was supposed to do this. Soccer related stuff. Euro Cup
              Thing. I don't think I ever knew about this thing's existence
              until I had to suffer through making this.{' '}
              <a
                href='https://daily-react-f606d.web.app/'
                rel='noopener noreferrer'
                target='_blank'
              >
                Euro Cup Results Website
              </a>{' '}
            </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='chicken'
            src='images/poster1.jpg'
          />
        </div>
      </div>

      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/graphing'>
                2020 project 2.0
              </a>
            </h3>
            <p class='card-text mb-auto'>
              My challenge is to create a graphing website for quadratic
              equations. I used{' '}
              <a
                href='https://react-charts.js.org/'
                rel='noopener noreferrer'
                target='_blank'
              >
                React Charts
              </a>{' '}
              to help. Issues that need to be fixed: If one things empty make it
              zero
            </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='quote'
            src='images/formula.png'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>June 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/transformation'>
                First 2020 project
              </a>
            </h3>
            <p class='card-text mb-auto'>
              My challenge is to create a new and improved digital version of
              the quote "whatever you do, do it well". I used{' '}
              <a
                href='https://material-ui.com/'
                rel='noopener noreferrer'
                target='_blank'
              >
                Material Ui
              </a>{' '}
              to help. ---------- That's a lie. I never finished this:) And I
              probably never will. (2022)
            </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='quote'
            src='images/quote2.png'
          />
        </div>
      </div>

      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                JAVA Script
              </a>
            </h3>
            <p class='card-text mb-auto'>
              In this website, I used JAVA Script to do everything{' '}
            </p>
            <p class='card-text mb-auto'>
              <a href='/coding'>Coding Challenge</a>
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/java.jpeg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Mobile App
              </a>
            </h3>
            <p class='card-text mb-auto'>
              I made a mobile app called...jk I was beginning one but then time
              ran out and I ended up not.
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/appstore.webp'
            data-holder-rendered='true'
          />
        </div>
      </div>
    </div>
  </div>
)

export default Programming
