import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className='blog-footer'>
        <p>
          Built for <a href='https://getbootstrap.com/'>me</a> by{' '}
          <a href='emily.andmap.co'>Emily</a>>:D
        </p>
        <p>
          <a href='/'>Back to top</a>
        </p>
      </footer>
    );
  }
}
export default Footer;
