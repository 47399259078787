import React from 'react'

const Art = () => (
  <div class='container'>
    <div class='jumbotron p-3 p-md-5 text-white rounded bg-warning'>
      <div class='col-md-6 px-0'>
        <h1 class='display-4 font-italic'>Art...</h1>
        <p class='lead my-3'>While learning art...</p>
        <p class='lead mb-0'>
          <a
            href='https://www.udemy.com/'
            rel='noopener noreferrer'
            target='_blank'
            class='text-white font-weight-bold'
          >
            Link of the art tutorial I used (2018)
          </a>
        </p>
      </div>
    </div>

    <div class='row mb-2'>
      {/*    <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Yuh</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle20.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Yuh</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle19.jpg'
          />
        </div>
      </div>
*/}
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Nugget Head</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle18.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Rainbows and Sparkles</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle17.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> uwu </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle16.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'>
              {' '}
              Fyi, that's an ice cream cone..... Eat 'em up, eat 'em up
            </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle15.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> The croissant grind never stops</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle14.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Eric</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle13.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Stacy</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle12.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Joseph</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle11.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Paul</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle10.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Lenny </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle9.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Dnf </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle8.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Spongebob</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle7.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Shapes</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle6.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Friends and Fam </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle5.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Blobs of Stuff </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle4.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> His face is an octogon </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle3.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Stack of Food (feat: Dadish)</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle2.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Doodle Spam
              </a>
            </h3>
            <p class='card-text mb-auto'> Burrito Angel</p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Doodles'
            src='images/doodle1.jpeg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>March 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                My Eye
              </a>
            </h3>
            <p class='card-text mb-auto'>
              The first actual thing that I drew in Udemy was an eye. I've never
              drawn anything realistic before, so I'm pretty proud of this:D{' '}
            </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='My Eye'
            src='images/eye.png'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Face things
              </a>
            </h3>
            <p class='card-text mb-auto'>
              This is my drawing of lips, ears, and hair. I like the hair the
              best. I drew a nose, but it didn't go so well, so I got rid of it
              and just drew the other things...;)
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/hairgood.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                5th grade Memories(Title)
              </a>
            </h3>
            <p class='card-text mb-auto'>
              This is my title for the 5th grade memories! When I finished the
              top half of the drawing I started the bottom half. I had no idea
              how I should draw it. I got an idea, but once I finished the
              drawing i didn't like it. So I cut it off with my scissors and
              taped another sheet of paper onto it. I made it like a galexy-ish,
              but it ended out like scribbles of black, purple, and blue. (Idk
              what I was trying to do -2020)
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/title.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                5th grade Memories(Science Camp)
              </a>
            </h3>
            <p class='card-text mb-auto'>
              I really liked science camp because we had rock climbing, archery,
              hiking, plays, and THE BEDS WERE AWESOME (even tho no one agrees
              with me).
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Camp'
            src='images/camp.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                5th grade Memories(Softball)
              </a>
            </h3>
            <p class='card-text mb-auto'>
              Softball was really fun, even if I was bad. We had a softball
              tournament and my team was called T.N.T. (AKA Toxic Nuclear
              Trashbags). I'm proud to say that I helped come up with it.
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='softball'
            src='images/softball.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                5th grade Memories(Carnival)
              </a>
            </h3>
            <p class='card-text mb-auto'>
              Every year, 5th graders had to put together a carnival for
              different grades to come. We even had to learn a dance to do at
              the end of every group. My friends and I did a face painting
              thing, which was pretty fun. Some kid wanted to look like a
              strawberry and told us to paint his whole face.
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/carnival.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                5th grade Memories(Dance Jam)
              </a>
            </h3>
            <p class='card-text mb-auto'>
              I think this was basically the dances we had to learn. Skrrt skrrt
              was like the best part.
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='dancing'
            src='images/jam.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                5th grade Memories(Pool Party)
              </a>
            </h3>
            <p class='card-text mb-auto'>
              The 5th grade pool party came on the last day of school. So much
              fun. My teacher, Mr. Wilson, made hot dogs while we were in the
              pool and they were delicious.
            </p>
          </div>
          <img
            class='card-img-right top-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='pool'
            src='images/pool.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
    </div>
  </div>
)

export default Art
