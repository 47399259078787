import React from 'react'

const Chinese = () => (
  <div class='container'>
    <div class='jumbotron p-3 p-md-5 text-white rounded bg-danger'>
      <div class='col-md-6 px-0'>
        <h1 class='display-4 font-italic'>Learning Chinese</h1>
        <p class='lead my-3'>While learning chinese...</p>
        <p class='lead mb-0'>
          <a href='/' class='text-white font-weight-bold'>
            ThEnd...
          </a>
        </p>
      </div>
    </div>

    <div class='row mb-2'>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2022
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Chinese Update
              </a>
            </h3>
            <div class='mb-1 text-muted'>6/22</div>
            <p class='card-text mb-auto'>
              I think I got A+'s the whole school year for Chinese. Or at least
              that's the average. Next year I'm going to Chinese 380, so that
              means it's honors year 3. My listening definitely carries. I
              really, really, really want to be in the same class as Keira. It's
              so much better with her. This one time I went to Jeannie's German
              class, and I kinda popped off. They were doign a quizlet live on
              like new grammar or smth, and I got second place. jeannie got
              first. But like. I basically won.
            </p>
          </div>
          <img
            class='card-img-right page-image'
            alt='Chinese'
            src='images/lamb-kabob.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2021
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Chinese at Hotchkiss
              </a>
            </h3>
            <div class='mb-1 text-muted'>6/14</div>
            <p class='card-text mb-auto'>
              I made it to Chinese 2, somehow, even tho I got 12.5/100 :D This
              means that I'll be forced to learn and practice every day, and my
              chinese will actually improve faster.
            </p>
          </div>
          <img
            class='card-img-right page-image'
            alt='Chinese'
            src='images/lamb-kabob.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>June 2021</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Chinese Progress:)))
              </a>
            </h3>
            <div class='mb-1 text-muted'>6/14</div>
            <p class='card-text mb-auto'>
              So. I basically gave up of Chinese, BUT I will be doing it in high
              school, so I will actually start being able to read and write
              more. Maybe. :D
            </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Chinese'
            src='images/orange_chicken.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>June 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                2 - 暑假单词
              </a>
            </h3>
            <div class='mb-1 text-muted'>6/25</div>
            <p class='card-text mb-auto'>
              欢 京 成 井 娃 鞠躬 夸 哈 歌 给 放 完 把 园 前 朝 外 身 少 汉语 教
              读书 喜欢 校 学习 跑 以后 本领 本溪 教室 讲 笔 这 现在 星期 车 问
              告诉 谁 听 请 谢 拍 拉 都 找 再见 牙 呀 敬 屋子 果 蛋 姐 岁 昨天
              卡 先 听话 觉.
            </p>
          </div>
          <img
            class='card-img-right top-image'
            alt='Chinese'
            src='images/food.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>June 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                1.5 - 暑假单词
              </a>
            </h3>
            <div class='mb-1 text-muted'>6/25</div>
            <p class='card-text mb-auto'>
              {' '}
              朵 玫瑰 菊花 东 南 西 北 起 骑 前 钱 奇怪 方向 面 认 虎 鹿 猪 老鼠
              跳舞 甜甜圈 新 年 热 闹 穿 衣 戴 帽 祝 身体 过 贴 联 贺 包 饺子
              庆团圆 拜 平安
            </p>
          </div>
          <img
            class='card-img-right  top-image'
            alt='Chinese'
            src='images/mochi.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>June 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                1 - 暑假单词
              </a>
            </h3>
            <div class='mb-1 text-muted'>6/25</div>
            <p class='card-text mb-auto'>
              秋 金 做 语 韵 乐 船 江 南 音 匀 西 季 画 大 升 国 旗 河 开 方 I
              learned how to read, write, and say all these words. It's 2021 now
              anddddddddd I remember FOUR WHOLE WORDS :O amazing. - - - - - - -
              - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
              - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
              - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
              - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
              - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
              - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
              - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - > :)
            </p>
          </div>
          <img
            class='card-img-right page-image'
            alt='Chinese'
            src='images/smore.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                小公鸡和小鸭子
              </a>
            </h3>
            <div class='mb-1 text-muted'>The Chick and The Duckling</div>
            <p class='card-text mb-auto'>
              (btw, all of these are traslated to English) Once upon a time, da
              chick and da duckling were buds. They went out to find some bugs
              to eat, but sadly, the duckling couldn't find any. Da chick
              noticed and since he already had a lot of bugs in his tummy he got
              more and gave them to da duckling. Then da duckling wanted to
              repay da chick by catching fish in the river and give them to da
              chick. Da duckling told da chick to stay since da chick couldn't
              swim, but da chick did not listen and quietly followed da duckling
              into da water. Da duckling caught fish but then, he heard da chick
              cry out, "Help!!!" Da duckling swim so fast to da chick's side. He
              let da chick climb on his back, and went back ashore. Da chick
              said, "Thank you."
            </p>
          </div>
          <img
            class='card-img-right  home-image'
            alt='duck+chick'
            src='images/ducklingchick.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2018</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                贪吃的小熊
              </a>
            </h3>
            <div class='mb-1 text-muted'>Greedy Bear</div>
            <p class='card-text mb-auto'>
              One day, Little bear hiked to a good friend's house. Little Bear
              was welcomed in by Rabbit. The bear had to leave but when he
              tried, he found out that he ate too much. Little Bear was to FAT
              to leave Rabbit's tiny house and got stuck in the doorway. So
              Little Bear had to chill there without food or water until he gets
              thinner. After some time, the bear is still FAT but somewhat
              thinner hehe. A bunch of random peeps come and help pull the bear
              out of the doorway. THE END.
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='greedy bear'
            src='images/greedybear.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>
              August 2018
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                搜集智慧的乌龟
              </a>
            </h3>
            <div class='mb-1 text-muted'>The Intellegents Collecter Turtle</div>
            <p class='card-text mb-auto'>
              Once upon a time, there was a turtle that wanted everybody to
              think he was the most intellegent of them all. So he started
              collecting "knowledge" (who knows how). When he decided that he
              collected enough "knowledge", he tried to put it in a tree to keep
              it safe. A human came by and made fun of the turtle because he was
              struggling to climb the tree. Blah, blah, blah...Turtle relizes
              he's not that smart and even the man was smarter. THE END.{' '}
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='genius turtle'
            src='images/turtle.png'
          />
        </div>
      </div>
    </div>
  </div>
)

export default Chinese
