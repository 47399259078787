import React from 'react';

const Camps = () => (
  <div class='container'>
    <div class='jumbotron p-3 p-md-5 text-white rounded bg-danger'>
      <div class='col-md-6 px-0'>
        <h1 class='display-4 font-italic'>Camp Stuff</h1>
        <p class='lead my-3'>During camp,</p>
        <p class='lead mb-0'>
          <a href='/' class='text-white font-weight-bold'>
            ThEnd...
          </a>
        </p>
      </div>
    </div>

    <div class='row mb-2'>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2021
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Nothing
              </a>
            </h3>
            <div class='mb-1 text-muted'> </div>
            <p class='card-text mb-auto'>:D</p>
          </div>
          <img
            class='card-img-right  top-image'
            alt='Camp'
            src='images/nothing.jpg'
          />
        </div>
      </div>
      <div class='col-md-6'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2021
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Nothing
              </a>
            </h3>
            <div class='mb-1 text-muted'> </div>
            <p class='card-text mb-auto'>:D</p>
          </div>
          <img
            class='card-img-right  top-image'
            alt='Camp'
            src='images/nothing.jpg'
          />
        </div>
      </div>
      <div class='col-md-12'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>
              Summer 2019
            </strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                IPSF Summer Camp
              </a>
            </h3>
            <div class='mb-1 text-muted'> </div>
            <p class='card-text mb-auto'>
              I had a Summer camp that lasted 3 weeks and it was waking up at
              7:20, every day Monday through Friday. There was 3 periods, and
              ended at 2:10. That's more like Summer school. For first period I
              had mobile app develpment, second period it was art, and third
              period robotics. I'm not really interested in robotics, but as
              days went on, I started enjoying it (not rly tho). I'm not really
              a robot person so yea but art was pretty fun. The projects were
              intresting and nice. I had a good time in art. Mobile app
              development wasn't as bad as I thought, like robotics, it was
              actually better than I expected (still boring tho and what did I
              learn? NOTHING :D). We created games, from simple, to more complex
              ones. I made a good amount of friends, which was surprising. That
              is basically how everything went. (boringest camp I've ever done)
            </p>
          </div>
          <img
            class='card-img-right  top-image'
            alt='Camp'
            src='images/summercamp.jpg'
          />
        </div>
      </div>
    </div>
  </div>
);

export default Camps;
