import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
  root: {
    margin: 100,
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  coolColor1: {
    background: 'linear-gradient(100deg, #ff7a7a 30%, #ffca7a 70%)',
    borderRadius: 0,
    border: 0,
    color: '#000000',
    height: 48,
    padding: '0 20px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  coolColor2: {
    background: 'linear-gradient(100deg, #ffca7a 20%, #fffd7d 90%)',
    borderRadius: 0,
    border: 0,
    color: '#000000',
    height: 48,
    padding: '0 20px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  coolColor3: {
    background: 'linear-gradient(100deg, #fffd7d 10%,  #9dff82 70%)',
    borderRadius: 0,
    border: 0,
    color: '#000000',
    height: 48,
    padding: '0 20px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  coolColor4: {
    background: 'linear-gradient(100deg, #9dff82 10%, #82ffd5 70%)',
    borderRadius: 0,
    border: 0,
    color: '#000000',
    height: 48,
    padding: '0 20px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  coolColor5: {
    background: 'linear-gradient(100deg, #82ffd5 30%, #7aafff 70%)',
    borderRadius: 0,
    border: 0,
    color: '#000000',
    height: 48,
    padding: '0 20px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  coolColor6: {
    background: 'linear-gradient(100deg, #7aafff 30%, #ab7aff 70%)',
    borderRadius: 0,
    border: 0,
    color: '#000000',
    height: 48,
    padding: '0 20px',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
});

const Transformation = () => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  //const sentence = '♥ Hello Emily ♥';
  const sentence = 'Whatever you do, do it well';
  const [result, setResult] = useState(sentence);
  const toggleChecked = () => {
    setChecked((prev) => !prev);
    if (!checked) setResult(doTheMagic());
    else setResult(sentence);
  };
  const doTheMagic = () => {
    return (
      <Box textAlign='center'>
        {sentence.split(' ').map((word, idx) => (
          <Typography
            align='center'
            variant='h3'
            component='h2'
            display='inline'
            className={
              idx === 0
                ? classes.coolColor1
                : idx === 1
                ? classes.coolColor2
                : idx === 2
                ? classes.coolColor3
                : idx === 3
                ? classes.coolColor4
                : idx === 4
                ? classes.coolColor5
                : classes.coolColor6
            }
          >
            {' '}
            {word}
          </Typography>
        ))}
      </Box>
    );
  };

  return (
    <div class='container'>
      <Card className={classes.root} variant='outlined'>
        <CardContent>
          <Typography className={classes.pos} color='textSecondary'>
            {result}
          </Typography>
        </CardContent>
        <CardActions>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={checked} onChange={toggleChecked} />}
              label='DO THE "MAGIC"'
            />
          </FormGroup>
        </CardActions>
      </Card>
    </div>
  );
};

export default Transformation;
