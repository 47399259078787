import React from 'react'

const Donations = () => (
  <div class='container'>
    <div class='jumbotron p-3 p-md-5 text-white rounded bg-success'>
      <div class='col-md-12 px-0'>
        <h1 class='display-4 font-italic'>Plant Sale</h1>
        <p class='lead my-3'>
          We accidently grew some Hami melon seeds in our garden by tossing our
          melon that we ate's seeds into the dirt, so we decided to try selling
          them. Our plan is to donate the money we earned to those who are
          fighting Covid 19. If anyone is willing to buy a plant, we can only
          send it to places around Irvine, Mission Viejo, Ladera Ranch, San
          Clemente, and cities around there. There will also be instructions
          sent with the plant. :D
        </p>
        <p class='lead mb-0'>
          <a href='/' class='text-white font-weight-bold'>
            The minimum price is $1. Anyone that wants to donate more can:)
          </a>
        </p>
      </div>
    </div>

    <div class='row mb-2'>
      <div class='col-md-8'>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #1
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='plant #1'
            src='images/Plant-1.jpg'
          />
        </div>

        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #2
              </a>
            </h3>
            <p class='card-text mb-auto'>
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='plant #2'
            src='images/Plant-2.jpg'
            data-holder-rendered='true'
          />
        </div>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #3
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='plant #3'
            src='images/Plant-3.jpg'
          />
        </div>

        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #4
              </a>
            </h3>
            <p class='card-text mb-auto'>
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='plant #4'
            src='images/Plant-4.jpg'
            data-holder-rendered='true'
          />
        </div>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #5
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='plant #5'
            src='images/Plant-5.jpg'
          />
        </div>

        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #6
              </a>
            </h3>
            <p class='card-text mb-auto'>
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='plant #6'
            src='images/Plant-6.jpg'
            data-holder-rendered='true'
          />
        </div>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #7
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='plant #7'
            src='images/Plant-7.jpg'
          />
        </div>

        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #8
              </a>
            </h3>
            <p class='card-text mb-auto'>
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='plant #8'
            src='images/Plant-8.jpg'
            data-holder-rendered='true'
          />
        </div>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #9
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='plant #9'
            src='images/Plant-9.jpg'
          />
        </div>

        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #10
              </a>
            </h3>
            <p class='card-text mb-auto'>
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='plant #10'
            src='images/Plant-10.jpg'
            data-holder-rendered='true'
          />
        </div>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #11
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='plant #11'
            src='images/Plant-11.jpg'
          />
        </div>

        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #12
              </a>
            </h3>
            <p class='card-text mb-auto'>
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='plant #12'
            src='images/Plant-12.jpg'
            data-holder-rendered='true'
          />
        </div>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #13
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='plant #13'
            src='images/Plant-13.jpg'
          />
        </div>

        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #14
              </a>
            </h3>
            <p class='card-text mb-auto'>
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='plant #14'
            src='images/Plant-14.jpg'
            data-holder-rendered='true'
          />
        </div>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #15
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='plant #15'
            src='images/Plant-15.jpg'
          />
        </div>

        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #16
              </a>
            </h3>
            <p class='card-text mb-auto'>
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='plant #16'
            src='images/Plant-16.jpg'
            data-holder-rendered='true'
          />
        </div>
        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-primary'>July 2020</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #17
              </a>
            </h3>

            <p class='card-text mb-auto'>
              {' '}
              If you want this plant, please contact me through email. You can
              see all of the contact info in the About Me section. In the email,
              add your address, plant #, and amount of $ you want to donate.
              Thank you:D
            </p>
          </div>
          <img
            class='card-img-right home-image'
            alt='plant #17'
            src='images/Plant-17.jpg'
          />
        </div>

        <div class='card flex-md-row mb-4 box-shadow h-md-250'>
          <div class='card-body d-flex flex-column align-items-start'>
            <strong class='d-inline-block mb-2 text-success'>July 2022</strong>
            <h3 class='mb-0'>
              <a class='text-dark' href='/'>
                Plant #18
              </a>
            </h3>
            <p class='card-text mb-auto'>
              Jk plant #18 doesn't exist. I think all these plants are dead now.
              Um. But they lived a good life. May they rest in peace. Now part
              of the earth, the air, the stuffs. They've decomposed and now are
              part of everything and nothing all at once.
            </p>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='plant #18'
            src='images/plant-heaven.jpeg'
            data-holder-rendered='true'
          />
        </div>
      </div>
    </div>
  </div>
)

export default Donations
