import React from 'react'

const Home = () => (
  <div className='container'>
    <div className='jumbotron p-3 p-md-5 text-white rounded bg-info'>
      <div className='col-md-12 px-0'>
        <h1 className='font-italic'>My oh so interesting activities :D </h1>
        <p className='lead my-3'>
          This is a website that I made for some reason and here I present you
          the beautiful random stuff I do with my time. ENJOY.
        </p>
      </div>
    </div>
    <div className='row mb-2'>
      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div
            className='card-body d-flex flex-column align-items-start'
            style={{ width: '200px' }}
          >
            <strong className='d-inline-block mb-2 text-primary'>Books</strong>
            <h3 className='mb-0'>Summer Reads</h3>
            <div className='mb-1 text-muted'>Summer of 2023</div>
            <p className='card-text mb-auto'>All School Read: Borderlands by Gloria Anzaldúa. </p>
            <a href='/books'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='hi'
            src='images/borderlands.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div
            className='card-body d-flex flex-column align-items-start'
            style={{ width: '200px' }}
          >
            <strong className='d-inline-block mb-2 text-success'>Books</strong>
            <h3 className='mb-0'>Summer Reads</h3>
            <div className='mb-1 text-muted'>Summer of 2022</div>
            <p className='card-text mb-auto'>
              Stuff I gotta read: Homegoing (Yaa Gyasi), #632 The Brain -is
              Wider than the Sky (Dickinson), The Vanishing Half (Bennett).
              First on my list for Summer reading ---->
            </p>
            <a href='/books'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='hi'
            src='images/homegoing.jpeg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div
            className='card-body d-flex flex-column align-items-start'
            style={{ width: '200px' }}
          >
            <strong className='d-inline-block mb-2 text-primary'>Hi</strong>
            <h3 className='mb-0'>Volunteering</h3>
            <div className='mb-1 text-muted'>Summer of 2021</div>
            <p className='card-text mb-auto'>Care package stuff....</p>
            <a href='/volunteer'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='hi'
            src='images/volunteer1.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-info'>Website</strong>
            <h3 className='mb-0'>Euro 2020</h3>
            <div className='mb-1 text-muted'>Summer of 2021</div>
            <p className='card-text mb-auto'>I made a website for Tony.</p>
            <a href='https://daily-react-f606d.web.app/'>Visit Site</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='jugglingness'
            src='images/poster1.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>

      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-warning'>Woo</strong>
            <h3 className='mb-0'>Graduation</h3>
            <div className='mb-1 text-muted'>June 2021</div>
            <p className='card-text mb-auto'>Escaped middle school.</p>
            <a href='/grad'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='jugglingness'
            src='images/lrmsgrad2.png'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div
            className='card-body d-flex flex-column align-items-start'
            style={{ width: '300px' }}
          >
            <strong className='d-inline-block mb-2 text-danger'>Fun</strong>
            <h3 className='mb-0'>Soccer</h3>
            <div className='mb-1 text-muted'>Summer of 2020</div>
            <div className='mb-auto '>
              This year I got to a new juggling record... the first two numbers
              add up to the third and all the numbers together equal 18. The
              first number is smaller than the second by 1. If you are STUMPED,
              continue reading heh.
            </div>

            <a href='/soccer'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='jugglingness'
            src='images/jugglin.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div
            className='card-body d-flex flex-column align-items-start'
            style={{ width: '200px' }}
          >
            <strong className='d-inline-block mb-2 text-primary'>
              Culture
            </strong>
            <h3 className='mb-0'>Learning Chinese</h3>
            <div className='mb-1 text-muted'>Summer of 2020</div>
            <p className='card-text mb-auto'>
              This year, my chinese progress was sorta going up. My dad created
              an app called{' '}
              <a
                href='https://play.google.com/store/apps/details?id=com.andmap.flashcard'
                rel='noopener noreferrer'
                target='_blank'
              >
                Vocabulary Victory
              </a>{' '}
              and you should check it out :D Maybe I'll start making some
              permanent progress cuz my brain keeps forgetting.
            </p>
            <a href='/chinese'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Chinese'
            src='images/chena.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-info'>Knowledge</strong>
            <h3 className='mb-0'>My Reading Log</h3>
            <div className='mb-1 text-muted'>Summer of 2020</div>
            <p className='card-text mb-auto'>
              Here I present to you, my book addiction. I could read this book
              over and over again without getting bored. Usually I read a book
              only once, but I've read The Keeper of the Lost Cities over 10
              times and the other books in the series at least 4 times.
            </p>
            <a href='/books'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='BooksYee'
            src='images/BookYee.png'
            data-holder-rendered='true'
          />
        </div>
      </div>

      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              Discipline
            </strong>
            <h3 className='mb-0'>Programming</h3>
            <div className='mb-1 text-muted'>Summer of 2020</div>
            <p className='card-text mb-auto'>
              This summer I had to do 3 projects. If you're somehow intrested in
              what I did, continue reading. And if you're not, don't continue
              reading:D I'm learning weird stuff that hurt my brain and yea.{' '}
            </p>
            <a href='/programming'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/hacker.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>

      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div
            className='card-body d-flex flex-column align-items-start'
            style={{ width: '200px' }}
          >
            <strong className='d-inline-block mb-2 text-danger'>
              Activity
            </strong>
            <h3 className='mb-0'>Summer Camp</h3>
            <div className='mb-1 text-muted'>Summer of 2019</div>
            <p className='card-text mb-auto'>
              I had a Summer camp that lasted 3 weeks and it was waking up at
              7:20, every day Monday through Friday. There was 3 periods, and
              ended at 2:10. That's more like Summer school.
              {/*
			  For first period I had mobile app develpment, second period it was art, and third period robotics.
              I'm not really intrested in robotics, but as days went on, I started enjoying it.
			  
			  I didn't like working with robots though... Art was
              pretty fun. The projects were intresting and nice. I had a good time in art. Mobile app development wasn't as bad as I thought, like robotics,
              it was actually better than I expected. We created games of all sorts, from simple, to more complex ones. I made a good amount of friends,
which was a huge relieve. That is basically how everything went.*/}
            </p>
            <a href='/camps'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/robotics.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>

      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-warning'>
              Culture
            </strong>
            <h3 className='mb-0'>Learning Chinese</h3>
            <div className='mb-1 text-muted'>Summer of 2019</div>
            <p className='card-text mb-auto'>
              I probably gained more progress than last summer. I can actually
              read some of the Chinese characters, but that's mostly because of
              the pinyin.
            </p>
            <a href='/chinese'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/duckyfish.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>

      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-primary'>
              Culture
            </strong>
            <h3 className='mb-0'>Learning Chinese</h3>
            <div className='mb-1 text-muted'>Summer of 2018</div>
            <p className='card-text mb-auto'>
              我是王加美(This is my chinese name) This is a picture of my book
              that I am learning from. 比如(for example) I am reading a story
              about a bear that ate too much food... very interesting indeed ._.
            </p>
            <a href='/chinese'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/chinese book.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              Creativity
            </strong>
            <h3 className='mb-0'>Time 4 Art</h3>
            <div className='mb-1 text-muted'>Summer of 2018</div>
            <p className='card-text mb-auto'>
              This is a picture of my art course that I learned drawing with...
            </p>
            <a href='/art'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/Art.png'
            data-holder-rendered='true'
          />
        </div>
      </div>

      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-warning'>
              Discipline
            </strong>
            <h3 className='mb-0'>Programming</h3>
            <div className='mb-1 text-muted'>Summer of 2018</div>
            <p className='card-text mb-auto'>
              This is a picture of the main website that I used to learn the
              different codes...
            </p>
            <a href='/programming'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/Programming pic.png'
            data-holder-rendered='true'
          />
        </div>
      </div>

      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-danger'>Fun</strong>
            <h3 className='mb-0'>Soccer</h3>
            <div className='mb-1 text-muted'>Summer of 2018</div>
            <p className='card-text mb-auto'>
              This is a picture of me juggling to 123. It was my new record. I
              accomplished it right before my 11th birthday...
            </p>
            <a href='/soccer'>Continue reading</a>
          </div>
          <img
            className='card-img-right home-image'
            alt='Thumbnail [200x250]'
            src='images/soccer.png'
            width='500'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-info'>Knowledge</strong>
            <h3 className='mb-0'>My Reading Log</h3>
            <div className='mb-1 text-muted'>Summer of 2018</div>
            <p className='card-text mb-auto'>
              This is a picture of a book my dad tried to get me to read, but my
              brain shut down the second I read the first three words. IT'S SO
              BORING I CAN'T READ IT. :)
            </p>
            <a href='/books'>Continue reading</a>
          </div>
          <img
            class='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='book'
            src='images/culture code.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
    </div>
  </div>
)

export default Home
