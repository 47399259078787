import React from 'react'

const Books = () => {
  const name = ' '
  return (
    <div class='container'>
      <div class='jumbotron p-3 p-md-5 text-white rounded bg-dark'>
        <div class='col-md-6 px-0'>
          <h1 class='display-4 font-italic'>Books...</h1>
          <p class='lead my-3'>While reading books...</p>
          <p class='lead mb-0'>
            <a href='/' class='text-white font-weight-bold'>
              ThEnd... {name}
            </a>
          </p>
        </div>
      </div>

      <div class='row mb-2'>
        <div class='col-md-6'>
          <div class='card flex-md-row mb-4 box-shadow h-md-250'>
            <div class='card-body d-flex flex-column align-items-start'>
              <strong class='d-inline-block mb-2 text-success'>
                Summer 2023
              </strong>
              <h3 class='mb-0'>
                <a class='text-dark' href='/'>
                  Hotchkiss Book Read #3
                </a>
              </h3>

              <p class='card-text mb-auto'>
                {' '}
                This book is about the mixes of cultures, backgrounds, languages, etc. It presents us a sort of "borderland" that sits right in the middle of two different __blanks__. The novel speaks on what is supposely "right" or "wrong" in our society and on how many people begin surpressed by these societal "norms" before being able to break through and find themselves within themselves...👍
              </p>
            </div>
            <img
              class='card-img-right top-image'
              alt='BOOKS'
              src='images/borderlands.jpg'
            />
          </div>
        </div>
        <div class='col-md-6'>
          <div class='card flex-md-row mb-4 box-shadow h-md-250'>
            <div class='card-body d-flex flex-column align-items-start'>
              <strong class='d-inline-block mb-2 text-success'>
                Summer 2022
              </strong>
              <h3 class='mb-0'>
                <a class='text-dark' href='/'>
                  Hotchkiss Book Read Part 2
                </a>
              </h3>

              <p class='card-text mb-auto'>
                {' '}
                The all-school read... Homegoing by Yaa Gyasi
              </p>
            </div>
            <img
              class='card-img-right top-image'
              alt='BOOKS'
              src='images/homegoing.jpeg'
            />
          </div>
        </div>
        <div class='col-md-6'>
          <div class='card flex-md-row mb-4 box-shadow h-md-250'>
            <div class='card-body d-flex flex-column align-items-start'>
              <strong class='d-inline-block mb-2 text-success'>
                Summer 2022
              </strong>
              <h3 class='mb-0'>
                <a class='text-dark' href='/'>
                  Hotchkiss Book Read Part 2
                </a>
              </h3>

              <p class='card-text mb-auto'>
                {' '}
                Hotchkiss gave us a bunch of book options to choose from. My
                friend said this book was good, so I'll read it. I searched it
                up and I think it's kinda similar to Homegoing, but we'll see.{' '}
              </p>
            </div>
            <img
              class='card-img-right top-image'
              alt='BOOKS'
              src='images/vanishinghalf.jpeg'
            />
          </div>
        </div>
        <div class='col-md-6'>
          <div class='card flex-md-row mb-4 box-shadow h-md-250'>
            <div class='card-body d-flex flex-column align-items-start'>
              <strong class='d-inline-block mb-2 text-warning'>
                Summer 2021
              </strong>
              <h3 class='mb-0'>
                <a class='text-dark' href='/'>
                  Hotchkiss Book Read
                </a>
              </h3>

              <p class='card-text mb-auto'>
                {' '}
                This is the other book I have to read for Hotchkiss. It's about
                wisdom. Mhm. This the all-school read. 2/10. The only part I
                liked was Skywoman Falling.
              </p>
            </div>
            <img
              class='card-img-right top-image'
              alt='BOOKS'
              src='images/hotchkiss-book-2.jpg'
            />
          </div>
        </div>
        <div class='col-md-6'>
          <div class='card flex-md-row mb-4 box-shadow h-md-250'>
            <div class='card-body d-flex flex-column align-items-start'>
              <strong class='d-inline-block mb-2 text-warning'>
                Summer 2021
              </strong>
              <h3 class='mb-0'>
                <a class='text-dark' href='/'>
                  Hotchkiss Book Read
                </a>
              </h3>

              <p class='card-text mb-auto'>
                {' '}
                One of the books that I have to read for Hotchkiss is called
                "The Septembers of Shiraz". Not very interesting to read. Sadly,
                I must finish it. It's about a guy who is arrested because
                people think he's a Zionist spy:D... 3/10. Dude was just in
                jail, and that was that. Eh...
              </p>
            </div>
            <img
              class='card-img-right top-image'
              alt='BOOKS'
              src='images/hotchkiss-book-1.jpg'
            />
          </div>
        </div>
        <div class='col-md-6'>
          <div class='card flex-md-row mb-4 box-shadow h-md-250'>
            <div class='card-body d-flex flex-column align-items-start'>
              <strong class='d-inline-block mb-2 text-primary'>
                July 2018
              </strong>
              <h3 class='mb-0'>
                <a class='text-dark' href='/'>
                  1 Million Word Challenge
                </a>
              </h3>

              <p class='card-text mb-auto'>
                {' '}
                In middle school, we have a million word reader challenge. I
                read plenty of books in my free time. Fiction books only tho.
                Non-fiction bores my brain to it's finest:)
              </p>
            </div>
            <img
              class='card-img-right top-image'
              alt='BOOKS'
              src='images/somebooks.gif'
            />
          </div>
        </div>
        <div class='col-md-6'>
          <div class='card flex-md-row mb-4 box-shadow h-md-250'>
            <div class='card-body d-flex flex-column align-items-start'>
              <strong class='d-inline-block mb-2 text-success'>
                August 2018
              </strong>
              <h3 class='mb-0'>
                <a class='text-dark' href='/'>
                  Favorite Series
                </a>
              </h3>
              <p class='card-text mb-auto'>
                My favorite book series is Keeper of the Lost Cities. It's a
                fictional fantasy book series and Shannon Messenger is the
                author.
              </p>
            </div>
            <img
              class='card-img-right top-image'
              data-src='holder.js/200x250?theme=thumb'
              alt='Thumbnail [200x250]'
              src='images/favbook.jpg'
              data-holder-rendered='true'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Books
