import React from 'react';
import { post, get } from '../../helpers/fetch';

class Coding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'Hello',
      result: '',
      method: 'GET',
      url: 'http://api.andmap.co/api/contacts',
      resultForGet: [],
      message: 'here is message box',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitUrl = this.handleSubmitUrl.bind(this);
    this.reverse = this.reverse.bind(this);
  }

  handleChange(event) {
    if (event.target.value.length <= 4) {
      this.setState({ message: 'Gimmie some more words' });
    } else {
      this.setState({ message: 'WoWa!!! that so perfecto:)!!!' });
    }

    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    this.setState({ result: this.reverse(this.state.value) });
  }

  handleSubmitUrl(event) {
    this.setState({ message: 'processing...' });
    if ('GET' === this.state.method)
      get(this.state.url, '')
        .then((json) => {
          this.setState({ resultForGet: json });
          this.setState({ message: 'done.' });
        })
        .catch((err) => {
          this.setState({ message: err.message });
        });
    else {
      const firstName = this.state.firstName;
      const lastName = '';
      const email = '';
      const message = '';
      post(this.state.url, { firstName, lastName, email, message }, '')
        .then((json) => {
          let users = this.state.resultForGet;
          users.push(json);
          this.setState({ resultForGet: users });
          this.setState({ firstName: '' });
          this.setState({ message: 'done.' });
        })
        .catch((err) => {
          this.setState({ message: err.message });
        });
    }
  }

  reverse(string) {
    let result = '';
    string.split('').forEach((element) => {
      result = element + result;
    });
    return result;
  }
  tableRow(user) {
    return (
      <tr key={user.contactId}>
        <td>{user.firstName}</td>
      </tr>
    );
  }

  render() {
    return (
      <div class='container'>
        <div class='jumbotron p-3 p-md-5 text-white rounded bg-primary'>
          <div class='col-md-6 px-0'>
            <h1 class='display-4 font-italic'>Coding...</h1>
            <p class='lead my-3'>While learning code...</p>
            <p class='lead mb-0'>ThEnd...</p>

            <div class='alert alert-success' role='alert'>
              {this.state.message}
            </div>
          </div>
        </div>

        <div class='row mb-2'>
          <div class='col-md-4'>
            <div class='alert alert-primary' role='alert'>
              <h4 class='alert-heading'>Reverse String!</h4>
              <div class='form-group'>
                <input
                  type='text'
                  class='form-control'
                  name='value'
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </div>
              <button
                type='button'
                class='btn btn-primary btn-sm'
                onClick={this.handleSubmit}
              >
                Reverse
              </button>
              <hr />
              {this.state.result}
            </div>
          </div>

          <div class='col-md-4'>
            <div class='alert alert-primary' role='alert'>
              <h4 class='alert-heading'>Save Contact</h4>
              <div class='form-group'>
                <input
                  type='text'
                  class='form-control'
                  name='firstName'
                  placeholder='First Name'
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
              </div>
              <div class='form-group'>
                <input
                  type='text'
                  name='url'
                  class='form-control'
                  value={this.state.url}
                  onChange={this.handleChange}
                />
              </div>
              <div class='form-group'>
                <select
                  name='method'
                  class='form-control'
                  value={this.state.method}
                  onChange={this.handleChange}
                >
                  <option value='POST'>POST</option>
                  <option value='GET'>GET</option>
                </select>
              </div>
              <button
                type='button'
                class='btn btn-primary btn-sm'
                onClick={this.handleSubmitUrl}
              >
                Submit
              </button>
              <hr />

              <table class='table'>
                <thead>
                  <tr>
                    <th>Contact List: </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.resultForGet.map((user) => {
                    if (user.firstName && '' !== user.firstName.trim())
                      return this.tableRow(user);
                    else return null;
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div class='p-4'>
            <h4 class='font-italic'>Archives</h4>
            <ol class='list-unstyled mb-0'>
              <li>
                <a href='/coding'>Coding Challenge</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

export default Coding;
