import React from 'react';
import './App.css';
import Home from './components/home';
import Chinese from './components/chinese';
import Camps from './components/camps';
import Art from './components/art';
import Soccer from './components/soccer';
import Books from './components/books';
import Fun from './components/fun';
import Hotchkiss from './components/hotchkiss';
import Volunteer from './components/volunteer/volunteer';
import Grad from './components/middleschoolgrad/grad';
import Footer from './components/footer';
import Header from './components/header';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Programming from './components/programming';
import Coding from './components/programming/coding';
import Graphing from './components/programming/graphing';
import Transformation from './components/programming/transformation';
import Donations from './components/donations';
import Roadtrip from './components/roadtrip';

const App = () => (
  <Router>
    <div>
      <Header />
      <Route exact path='/' component={Home} />
      <Route path='/chinese' component={Chinese} />
      <Route path='/camps' component={Camps} />
      <Route path='/art' component={Art} />
      <Route path='/soccer' component={Soccer} />
      <Route path='/fun' component={Fun} />
      <Route path='/hotchkiss' component={Hotchkiss} />
      <Route path='/volunteer' component={Volunteer} />
      <Route path='/grad' component={Grad} />
      <Route exact path='/coding' component={Coding} />
      <Route exact path='/transformation' component={Transformation} />
      <Route exact path='/graphing' component={Graphing} />
      <Route exact path='/programming' component={Programming} />
      <Route path='/books' component={Books} />
      <Route path='/donations' component={Donations} />
      <Route path='/roadtrip' component={Roadtrip} />
      <Footer />
    </div>
  </Router>
);

export default App;
