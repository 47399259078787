import React from 'react'

const Soccer = () => (
  <div className='container'>
    <div className='jumbotron p-3 p-md-5 text-white rounded bg-success'>
      <div className='col-md-6 px-0'>
        <h1 className='display-4 font-italic'>Soccer</h1>
        <p className='lead my-3'>While playing soccer...</p>
        <p className='lead mb-0'>
          <a href='/' className='text-white font-weight-bold'>
            ThEnd...
          </a>
        </p>
      </div>
    </div>

    <div className='row mb-2'>
      <div className='col-md-6'>
        <div
          id='#freekick'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              Summer 2022
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Somehow Not Losing the Ball
              </a>
            </h3>

            <p className='card-text mb-auto'> Hotchkiss Soccer Season (Fall)</p>
          </div>

          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/gl1uEhKDgiE'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className='col-md-6'>
        <div
          id='#freekick'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              Summer 2022
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Dribble, Cross
              </a>
            </h3>

            <p className='card-text mb-auto'> Hotchkiss Soccer Season (Fall)</p>
          </div>

          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/FYzprrZXQd8'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className='col-md-6'>
        <div
          id='#aroundtheworld'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              June 2021
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Freekick
              </a>
            </h3>

            <p className='card-text mb-auto'>
              {' '}
              Curvier-ness is yes-er (with left foot)
            </p>
          </div>

          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/SwybLi4M8nQ'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className='col-md-6'>
        <div
          id='#aroundtheworld'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              June(?) 2021
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Around the World
              </a>
            </h3>

            <p className='card-text mb-auto'>
              {' '}
              Woo. Didn't know my foot was capable.
            </p>
          </div>

          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/bTDT8quv7hA'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className='col-md-6'>
        <div
          id='#aroundtheworld'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              June 2021
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Volley
              </a>
            </h3>

            <p className='card-text mb-auto'> Volley-ing. It hurt my foot.</p>
          </div>
          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/W2R-BIdbIU4'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className='col-md-6'>
        <div id='#volley' className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              June 2021
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Bicycle Kick
              </a>
            </h3>

            <p className='card-text mb-auto'>
              {' '}
              I did a bicycle. Weak, but is yes.
            </p>
          </div>

          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/GHjQjCDpEnc'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div className='col-md-6'>
        <div
          id='#bicycle_kick'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>2021</strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Juggling
              </a>
            </h3>

            <p className='card-text mb-auto'>
              {' '}
              During early-ish 13, I got a new juggling record of 609, which is
              depressing. All I wanted was to have a cool number like 711, but
              no. I MESSED UP. Juggling to 609 was way too boring, so I dunno
              when I'll ever try to beat it. I do want 711 tho. We'll see when I
              turn 14._.
            </p>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='juggling'
            src='images/pro_chicken_soccer.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div
          id='#freekick'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              Summer 2020
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Headers
              </a>
            </h3>

            <p className='card-text mb-auto'> 2020 High Score: 24 I think</p>
          </div>

          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/T2JNF-_Ic7o'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className='col-md-6'>
        <div
          id='#aroundtheworld'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              Summer 2020
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Juggling with Dad
              </a>
            </h3>

            <p className='card-text mb-auto'> Juggle, juggle</p>
          </div>

          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/2pYWoe996MQ'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className='col-md-6'>
        <div
          id='#juggling'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>2020</strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Juggling
              </a>
            </h3>

            <p className='card-text mb-auto'>
              {' '}
              459 is my new record. I'm probably not gonna do any better for a
              while. I got to it when I was 12 sorta almost 13. I wish I got to
              500 but no, both my feet failed me when I was so close to half a
              thousand. So sad ;_;
            </p>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='juggling'
            src='images/soccerdoggo.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div
          id='#juggling'
          className='card flex-md-row mb-4 box-shadow h-md-250'
        >
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>2019</strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Juggling
              </a>
            </h3>

            <p className='card-text mb-auto'>
              {' '}
              Juggling to 123 was my new record. I accomplished it right before
              my 11th birthday...(After I turned 11) In the middle of 11, I
              juggled to 130 which broke my old record.
            </p>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='juggling'
            src='images/jugglin.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div id='#' className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-primary'>
              July 2018
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                No Practice?
              </a>
            </h3>

            <p className='card-text mb-auto'>
              {' '}
              On the days with no soccer practice, my fam and I would go to Cox
              to play SOCCCERRR. Sometimes there would be other people and we'd
              ask them if they want to have a scrimmage. Somehow, everyone we
              asked are boys/men and way older than me >:( but you know, I still
              rocked ;) ....
            </p>
          </div>
          <img
            className='card-img-right home-image'
            alt='Soccer'
            src='images/pvsoccer.jpg'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div id='#' className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              July 2018
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Referee
              </a>
            </h3>

            <p className='card-text mb-auto'>
              {' '}
              I was forced to do beginner referee training by my dad. The first
              time I did it, I yawned 300000 gazillion times. People probably
              thought there was something wrong with me. It was way too boring
              for me. When it was finally time for the test I got ready to
              start, looking over my notes one last time (not that I payed that
              much attention to write that many notes). In the end, I got 7
              incorrect, but I still made it because you can have under 11
              questions wrong. Several months later(July 2018), I took another
              test for reffing. I guess I wasn't AS bored, but I overheated
              whenever I went outside. This time I got 6 wrong. (The referee
              coach's names were David and Bruce. Idk why you would wanna know
              that tho). So yeeeaaaa :P
            </p>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Thumbnail [200x250]'
            src='images/refe.jpg'
            data-holder-rendered='true'
          />
        </div>
      </div>
      <div className='col-md-6'>
        <div id='irvine' className='card flex-md-row mb-4 box-shadow h-md-250'>
          <div className='card-body d-flex flex-column align-items-start'>
            <strong className='d-inline-block mb-2 text-success'>
              August (18-19) 2018
            </strong>
            <h3 className='mb-0'>
              <a className='text-dark' href='/'>
                Irvine Tornament
              </a>
            </h3>

            <p className='card-text mb-auto'>
              {' '}
              My AYSO team had an tournament against club teams. When we went
              against pateadores, we won 1-0. The next game we won 3?-0 against
              Slammers. I was very sore after every game. The next day, we had
              two more games. First game was 3-0 against Warriors. We got into
              the finals and had to come onto the field in a very "fancy" way.{' '}
              After lots of hard, tiring work we won 1-0 against OC Union. We
              got first place!!! Last time, we got second.
            </p>
          </div>
          <img
            className='card-img-right home-image'
            data-src='holder.js/200x250?theme=thumb'
            alt='Tournament'
            src='images/yaaa.png'
            data-holder-rendered='true'
          />
        </div>
      </div>
    </div>
  </div>
)

export default Soccer
