import React from 'react';

const data = [
  {
    img: 'images/lrmsgrad3.jpg',
    caption: `June 2021 - Taylor's Grad Party`,
  },
  {
    img: 'images/lrmsgrad5.png',
    caption: 'June 2021 - THE FOOD TABLE',
  },
  {
    img: 'images/lrmsgrad6.png',
    caption: 'June 2021 - BOOM, BOP, BAM',
  },
  {
    img: 'images/lrmsgrad1.jpg',
    caption: 'June 2021 - Graduated',
  },
];
const Grad = () => (
  <div class='container'>
    <div class='jumbotron p-3 p-md-5 text-white rounded grad'>
      <div class='row mb-2'>
        <div class='col-md-8 px-0'>
          <h1 class='display-4 font-italic'>Middle School Graduation</h1>
          <p class='lead my-3'>...</p>
          <p class='lead mb-0'>Onto high school.</p>
        </div>
        <div class='col-md-4 px-0'>
          {' '}
          <div class='padding70'>
            <img
              class='card-img-right top-image'
              data-src='holder.js/200x250?theme=thumb'
              alt='grad_logo'
              src='images/lrms.png'
              data-holder-rendered='true'
              data-toggle='modal'
              data-target='#grad_logoModal'
            />
          </div>
        </div>{' '}
      </div>
    </div>

    <div class='row mb-2'>
      <div class='col-md-12'>
        <div class='row mb-2'>
          {data.map((image) => (
            <div class='col-md-6'>
              <div id='grad' class='card flex-md-row mb-4 box-shadow h-md-250'>
                <div class='card-body d-flex flex-column align-items-start'>
                  <img
                    class='bigimage'
                    data-src='holder.js/200x250?theme=thumb'
                    alt='grad'
                    src={`${image.img}`}
                    data-holder-rendered='true'
                    data-toggle='modal'
                    data-target='#gradModal'
                  />

                  <p class='card-text mb-auto mt-3'> {image.caption}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Grad;
